import React from "react";
import classes from "./selfImage.module.scss";

export default function SelfImage() {
  return (
    <img
      className={classes.selfImage}
      src='https://res.cloudinary.com/dfhtkgibn/image/upload/v1715270613/portfolio/SelfImageLarge_ly410l.webp'
      alt="Nishant Naithani"
      loading="lazy"
    />
  );
}
