import "./App.scss";
import Home from "./pages/Home/Home";


function App() {
  return (
    <div className="main">
      <Home />
    </div>
  );
}

export default App;
