import React from "react";
import { Introduction, SelfImage } from "./components";

import classes from './home.module.scss';

function Home() {
  return (
    <div className={classes.home}>
      <SelfImage />
      <Introduction />
    </div>
  );
}

export default Home;
