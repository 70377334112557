import React from "react";
import classes from "./introduction.module.scss";

export default function Introduction() {
  return (
    <div className={classes.introduction}>
      <span className={classes.heading}>Hi, I'm Nishant Naithani!</span>
      <span className={classes.otherData}>
        A Senior Software Development Engineer specializing in crafting dynamic
        Web Applications using cutting-edge technologies such as ReactJS and
        Node.js. Currently, I thrive in my role at{" "}
        <a
          href="https://www.peoplegrove.com/"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.peopleGrove}
        >
          PeopleGrove
        </a>
        .
      </span>
      <span className={classes.otherData}>
        I'm passionate about building robust web solutions that enhance user
        experiences and drive business growth.
      </span>
      <span className={classes.otherData}>
        Explore my journey in detail by downloading my cv{" "}
        <a
          href={process.env.PUBLIC_URL + "/docs/Resume.pdf"}
          download="Nishant_Naithani_CV"
          target="_blank"
          rel="noreferrer"
          className={classes.resume}
        >
          here
        </a>{" "}
        or connect with me on{" "}
        <a
          href="https://www.linkedin.com/in/nishant-naithani-070197/"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.linkedIN}
        >
          LinkedIN
        </a>{" "}
        to stay updated on my professional insights.
      </span>
    </div>
  );
}
